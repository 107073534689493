import React from "react";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();
  return (
    <div
      data-w-id="9d857a77-ab0e-d8a7-afd4-9cdd499c84be"
      className="section cc-background-image"
    >
      <div className="container">
        <div
          data-w-id="08f1dad3-3a64-6e0c-7aad-18ed073821a4"
          className="testimonial"
        >
          <div className="testimonial-frame">
            <div className="w-dyn-list">
              <div role="list" className="w-dyn-items">
                <div role="listitem" className="w-dyn-item">
                  <div className="blockquote-wrapper">
                    <div className="quote-icon">“</div>
                    <blockquote className="blockquote">
                      {t("testimonialsSection.heading")}
                    </blockquote>
                    <div className="cite">
                      <div className="separator"></div>
                      <h4 className="label cc-primary-text">
                        {t("testimonialsSection.name")}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/161a4d32-4a5b-43c2-9471-d09d8a690200/public"
        loading="lazy"
        alt="Flowers - Blume Photography Webflow Template"
        sizes="100vw"
        className="parallax-image"
        style={{
          willChange: "transform",
          transform:
            "translate3d(0px, 28.1865vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
      />
    </div>
  );
};

export default Testimonials;
