// using module.exports here so gatsby-config can read it

const siteUrlDefault = "https://austinproduct.photography/";
const config = {
  title: "Austin Texas Product Photographer",
  titleTemplate: "%s",
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: "Austin Texas Product Photographer",
  description:
    "Austin Texas Product Photographer in the US. E-commerce, Amazon, Shopify, Etsy, Ebay, and more.",
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  postThumbnail: "",
  twitterUsername: "",
  airtableTables: ["blog"],
  mainColor: "#4B8EF9",
  // those fields are required for the page generation process.
  airtableSpecialFields: ["slug", "title", "description"],
};

module.exports = config;
